import React from 'react';
import * as linkStyles from './link-button.module.scss';
import classNames from 'classnames';
import { Link } from 'gatsby';

type LinkButtonProps = {
    path: string,
    className?: string
}

const LinkButton: React.FC<LinkButtonProps> = ({ path, className, children }) => {
    return (
        <Link className={classNames(className, linkStyles.link)} to={path}>{children}</Link>
    );
};

export default LinkButton;