// extracted by mini-css-extract-plugin
export var about = "services-module--about--tmTxt";
export var aboutContainer = "services-module--about-container--a2P1s";
export var aboutTitle = "services-module--about-title--rXlQG";
export var benefits = "services-module--benefits--FsS9a";
export var benefitsContainer = "services-module--benefits-container--HzAzu";
export var benefitsList = "services-module--benefits-list--Tr1r-";
export var benefitsSubtitle = "services-module--benefits-subtitle--kF2TH";
export var benefitsTitle = "services-module--benefits-title--4WQK2";
export var contact = "services-module--contact--Rvu25";
export var contactContainer = "services-module--contact-container--uF-Si";
export var contactLink = "services-module--contact-link--pOLG3";
export var contactSubtitle = "services-module--contact-subtitle--hQi75";
export var features = "services-module--features--9gmHi";
export var featuresContainer = "services-module--features-container--WLhtK";
export var featuresImage = "services-module--features-image--Aa1FX";
export var featuresList = "services-module--features-list--pDIHz";
export var featuresListItem = "services-module--features-list-item--9CiuO";
export var featuresSubtitle = "services-module--features-subtitle--3a9r6";
export var featuresTitle = "services-module--features-title--VRd5N";
export var main = "services-module--main---I5uk";
export var mainContainer = "services-module--main-container--W5Qym";
export var mainImage = "services-module--main-image--YnUf0";
export var mainSubtitle = "services-module--main-subtitle--BdQlf";
export var mainTitle = "services-module--main-title--ubfSN";
export var work = "services-module--work--hjBTk";
export var workContainer = "services-module--work-container--wSnb7";
export var workHeading = "services-module--work-heading--SoTUl";