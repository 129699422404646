import React from 'react';
import { WorkType } from '../../types/servicesTypes';
import * as styles from './featured-work-card.module.scss';
import { Zoom } from 'react-reveal';

type FeaturedWorkCardProps = {
    work: WorkType
}

const FeaturedWorkCard: React.FC<FeaturedWorkCardProps> = ({ work }) => {
    return (
        <article className={styles.workItem}>
            <Zoom>
                <div className={styles.workContent}>
                    <img className={styles.workIcon} src={work.icon} alt="" aria-hidden="true" />
                    <h3 className={styles.workTitle}>{work.title}</h3>
                    <p className={styles.workText}>{work.description}</p>
                </div>
            </Zoom>
            <img className={styles.workImage} src={work.image} alt="Work section image" />
        </article>
    )
}

export default FeaturedWorkCard;