import classNames from 'classnames';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from '../pages/services/services.module.scss';
import BodyText from '../components/body-text/BodyText';
import FeaturedWorkCard from '../components/featured-work-card/FeaturedWorkCard';
import Layout from '../components/layout/Layout';
import LinkButton from '../components/link-button/LinkButton';
import SmallServiceCard from '../components/service-card/SmallServiceCard';
import TitleH2 from '../components/title-h2/TitleH2';
import { pagePathEnum } from '../enums/pathEnum';

import { IServiceNode, IServicesPageData, ServicesPageFrontmatter } from '../types/servicesTypes';
import { serviceCardMaker, servicesContentMaker } from '../utils/servicesHandlers';
import { Fade, Zoom, Slide } from 'react-reveal';

type ServicesTemplateProps = {
    servicesCards: IServiceNode[]
    serviceContent: ServicesPageFrontmatter
}

export const ServicesTemplate: React.FC<ServicesTemplateProps> = ({ servicesCards, serviceContent }) => {
    const {
        title, subtitle, heading, topImage, topBackground, subImage, subBackground,
        subHeading, servicesBackground, services, offerHeading, offerText,
        worksHeading, worksBackground, works, conclusionHeading,
        conclusionBackground, contactHeading, contactBackground,
        contactSubheading, html
    } = serviceContent;
    return (
        <main>
            <section className={styles.main} style={{ backgroundImage: `url(${topBackground})` }}>
                <div className={classNames(classNames, styles.mainContainer, 'wrapper')}>
                    <Fade delay={500}>
                        <h1 className={styles.mainTitle}>{title}</h1>
                    </Fade>

                    <Fade delay={500}>
                        <p className={styles.mainSubtitle}>
                            {subtitle}
                        </p>
                    </Fade>

                    <img className={styles.mainImage} src={topImage} alt="Main sectiom" />
                </div>
            </section>

            <section className={styles.features} style={{ backgroundImage: `url(${subBackground})` }}>
                <div className={classNames(classNames, styles.featuresContainer, 'wrapper')}>
                    <Zoom big>
                        <img className={styles.featuresImage} src={subImage} alt="Features image" />
                    </Zoom>

                    <Slide left>
                        <TitleH2 className={styles.featuresTitle}>{heading}</TitleH2>
                    </Slide>

                    <Slide left>
                        <p className={styles.featuresSubtitle}>{subHeading}</p>
                    </Slide>

                    <Slide left>
                        <ul className={styles.featuresList}>
                            {services?.map((el, i) => <li key={i} className={styles.featuresListItem}>{el.title}</li>)}
                        </ul>
                    </Slide>
                </div>
            </section>

            <section className={styles.benefits} style={{ backgroundImage: `url(${servicesBackground})` }}>
                <div className={classNames(classNames, styles.benefitsContainer, 'wrapper')}>
                    <TitleH2 className={styles.benefitsTitle}>{offerHeading}</TitleH2>
                    <p className={styles.benefitsSubtitle}>
                        {offerText}
                    </p>

                    <Zoom>
                        <div className={styles.benefitsList}>
                            {servicesCards?.map((card, i) => <SmallServiceCard key={i} card={card} />)}
                        </div>
                    </Zoom>
                </div>
            </section>

            <section className={styles.work} style={{ backgroundImage: `url(${worksBackground})` }}>
                <div className={classNames(classNames, styles.workContainer, 'wrapper')}>
                    <TitleH2 className={styles.workHeading}>{worksHeading}</TitleH2>
                    {works?.map((el, i) => <FeaturedWorkCard key={i} work={el} />)}
                </div>
            </section>

            <section className={styles.about} style={{ backgroundImage: `url(${conclusionBackground})` }}>
                <div className={classNames(classNames, styles.aboutContainer, 'wrapper')}>
                    <Slide left>
                        <TitleH2 className={styles.aboutTitle}>{conclusionHeading}</TitleH2>
                    </Slide>
                    <Slide left>
                        <BodyText html={html} />
                    </Slide>
                </div>
            </section>

            <section className={styles.contact} style={{ backgroundImage: `url(${contactBackground})` }}>
                <Zoom>
                    <div className={classNames(classNames, styles.contactContainer, 'wrapper')}>
                        <TitleH2>{contactHeading}</TitleH2>
                        <p className={styles.contactSubtitle}>{contactSubheading}</p>
                        <LinkButton className={styles.contactLink} path={pagePathEnum.CONTACT_US}>CONTACT US</LinkButton>
                    </div>
                </Zoom>
            </section>
        </main>
    );
};

type ServicesContainerProps = {
    data: IServicesPageData
}

const ServicesContainer: React.FC<ServicesContainerProps> = ({ data }) => {
    const servicesCards: IServiceNode[] = serviceCardMaker(data.allMarkdownRemark.edges);
    const servicesPageContent = servicesContentMaker(data.allMarkdownRemark.edges);
    return (
        <Layout>
            <ServicesTemplate serviceContent={servicesPageContent[0]} servicesCards={servicesCards} />
        </Layout>
    );
};


export const servicesPageQuery = graphql`
  query {
    allMarkdownRemark(
        filter: {frontmatter: {templateKey: {in: ["services-template", "service-page-template"]}}}
      ) {
        edges {
          node {
            id
            frontmatter {
                title
                subtitle
                templateKey
                path
                date(formatString: "MMMM DD, YYYY")
                icon

                topImage
                topBackground
                subImage
                subBackground
                heading
                subHeading
                servicesBackground
                services {
                    title
                }
                offerHeading
                offerSubheading
                worksHeading
                worksBackground
                works {
                    icon
                    image
                    title
                    description
                }
                conclusionHeading
                conclusionBackground
                contactHeading
                contactBackground
                contactSubheading
            }
            html
          }
        }
      }
    }
`;

export default ServicesContainer;