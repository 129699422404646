import { IServicesPageNode } from "../types/servicesTypes";

export const servicesContentMaker = (edges: IServicesPageNode[]) => {
    return edges
        .filter((edge: IServicesPageNode) => edge.node.frontmatter.templateKey === "services-template")
        .map((edge: IServicesPageNode) => ({
            title: edge.node.frontmatter.title,
            subtitle: edge.node.frontmatter.subtitle,
            templateKey: edge.node.frontmatter.templateKey,
            path: edge.node.frontmatter.path,
            heading: edge.node.frontmatter.heading,
            topImage: edge.node.frontmatter.topImage,
            topBackground: edge.node.frontmatter.topBackground,
            subImage: edge.node.frontmatter.subImage,
            subBackground: edge.node.frontmatter.subBackground,
            subHeading: edge.node.frontmatter.subHeading,
            servicesBackground: edge.node.frontmatter.servicesBackground,
            services: edge.node.frontmatter.services,
            offerHeading: edge.node.frontmatter.offerHeading,
            offerSubheading: edge.node.frontmatter.offerSubheading,
            worksHeading: edge.node.frontmatter.worksHeading,
            worksBackground: edge.node.frontmatter.worksBackground,
            works: edge.node.frontmatter.works,
            conclusionHeading: edge.node.frontmatter.conclusionHeading,
            conclusionBackground: edge.node.frontmatter.conclusionBackground,
            contactHeading: edge.node.frontmatter.contactHeading,
            contactBackground: edge.node.frontmatter.contactBackground,
            contactSubheading: edge.node.frontmatter.contactSubheading,
            html: edge.node.html
        }));
}

export const serviceCardMaker = (edges: IServicesPageNode[]) => {
    return edges
        .filter((edge: IServicesPageNode) => edge.node.frontmatter.templateKey === "service-page-template")
        .map((edge: IServicesPageNode) => ({
            node: {
                id: edge.node.id,
                html: edge.node.html,
                frontmatter: {
                    date: edge.node.frontmatter.date,
                    bgIcon: edge.node.frontmatter.bgIcon,
                    icon: edge.node.frontmatter.icon,
                    path: edge.node.frontmatter.path,
                    templateKey: edge.node.frontmatter.templateKey,
                    title: edge.node.frontmatter.title
                }
            }
        }))
        .sort((edgeA: any, edgeB: any) => edgeA.node.frontmatter.title.localeCompare(edgeB.node.frontmatter.title))
}