import React from 'react';
import { IServiceNode } from '../../types/servicesTypes';
import LinkButton from '../link-button/LinkButton';
import * as styles from './service-card.module.scss';

type SmallServiceCardProps = {
    card: IServiceNode
}

const SmallServiceCard: React.FC<SmallServiceCardProps> = ({ card }) => {
    const { frontmatter } = card.node;

    return (
        <article className={styles.smallServiceCard}>
            <img className={styles.icon} src={frontmatter.icon} alt="" aria-hidden="true"  />
            <h3 className={styles.title}>{frontmatter.title}</h3>
            <p className={styles.subtitle}>{frontmatter.subtitle}</p>
            <LinkButton className={styles.link} path={frontmatter.path}>learn more</LinkButton>
        </article>
    )
}

export default SmallServiceCard