// extracted by mini-css-extract-plugin
export var icon = "service-card-module--icon--aNV8k";
export var link = "service-card-module--link--ledpC";
export var serviceCard = "service-card-module--service-card--NuRBF";
export var serviceCardIcon = "service-card-module--service-card-icon--cv+i9";
export var serviceCardText = "service-card-module--service-card-text--Yfsje";
export var serviceCardTextSubtitle = "service-card-module--service-card-text-subtitle--oVwpJ";
export var serviceCardTextTitle = "service-card-module--service-card-text-title--WZljp";
export var smallServiceCard = "service-card-module--small-service-card--HdhrM";
export var subtitle = "service-card-module--subtitle--P-8EU";
export var title = "service-card-module--title--IXdCZ";